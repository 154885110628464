<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <Filters @filters="setFilters" @telecallers="setTelecallers" />
    <div>
      <RegistrantDetails :filters="filters" :telecallers="telecallers"></RegistrantDetails>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import Filters from "./filters.vue";
import RegistrantDetails from "./RegistrantDetails.vue";

export default {
  components: {
    Layout,
    PageHeader,
    Filters,
    RegistrantDetails,
  },
  data() {
    return {
      title: "Registrants",
      items: [
        {
          text: "Medisage",
        },
        {
          text: "Registrants",
          active: true,
        },
      ],
      summary: {},
      filters: {},
      loading: true,
      telecallers : [],
    };
  },
  created() {
    // this.init();
  },
  methods: {
    init() {
      // this.loading = true;
      // this.loading = false;
    },
    setFilters(v) {
      this.filters = v;
    },
    setTelecallers(v){
      this.telecallers = v;
    }
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        // this.init();
      },
    },
  },
};
</script>
