<template>
  <div class="card">
    <div class="card-body">
      <div class="d-flex justify-content-between align-items-center">
        <!-- Search -->
        <div>
          <label class="d-flex align-items-center">
            <div style="width: 100%;">Search By Doctor's Name:</div>
            <b-form-input
              v-model="doctorName"
              v-on:keyup="searchByDoctor"
              @search="resetFilters"
              type="search"
              class="form-control ml-2"
            ></b-form-input>
          </label>
        </div>
        <div>
          <label class="d-flex align-items-center">
            <div style="width: 100%;">Search By Mobile Number:</div>
            <b-form-input
              v-model="mobileNumber"
              v-on:keyup="searchByMobile"
              @search="resetFilters"
              type="search"
              class="form-control ml-2"
            ></b-form-input>
          </label>
        </div>
        <!-- End search -->
        <!-- Prime User -->
        <div class="d-flex overall-count">
          <div class="mr-2 pb-2">Prime User</div>
          <Switches
            v-model="primeUser"
            type-bold="true"
            color="success"
            class="m-0"
          ></Switches>
        </div>
        <!-- Prime User End -->
      </div>
      <!-- <div class="d-flex justify-content-end">
        <JsonExcel
        class=""
        :data="users"
        name="members.csv"
        type="csv">
        <div class="btn pr-0"><i class="mdi mdi-download"></i> Download</div>
      </JsonExcel>
      </div> -->

      <div class="table-responsive">
        <b-table
          :items="users"
          :fields="fields"
          responsive="sm"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
        <template v-slot:cell(mci_verification_status)="row">
          <template v-if="row.item.registration_no != null && row.item.registration_no != ''">
              {{ '(' + row.item.registration_no + ') ' + row.item.mci_verification_status }}
          </template>
          <template v-if="row.item.registration_no == null || row.item.registration_no == ''">
              {{ row.item.mci_verification_status }}
          </template>
        </template>
        <template v-slot:cell(agent_name)="row">
          <template v-if="row.item.agent_name != null && row.item.agent_name != ''">
            {{ row.item.agent_name + ' (' + row.item.agent_type + ')' }}
          </template>
          <template v-if="row.item.agent_name == null || row.item.agent_name == ''">
            {{ }}
          </template>
        </template>
        <template v-slot:cell(device_type)="row">
            <div class="d-flex justify-content-center">
              <i v-if="row.item.device_type == 'android'" class="mdi mdi-android" style="color: #1cbb8c;font-size: 20px;"></i>
              <i v-else-if="row.item.device_type == 'ios'" class="mdi mdi-apple" style="color: #a4a4a4;font-size: 20px;"></i>
              <i v-else class="mdi mdi-web" style="color: #5664d2;font-size: 20px;"></i>
            </div>
          </template>
          <template v-slot:cell(is_prime)="row">
            <i
              class="fas fa-check-circle"
              style="color: #a4a4a4"
              v-if="row.item.is_prime !== 1"
            ></i>
            <i class="fas fa-check-circle" style="color: #1cbb8c" v-else></i>
          </template>
          
        </b-table>
      </div>
      <div class="row">
        <div class="col">
          <div class="dataTables_pa ginate paging_simple_numbers float-right">
            <ul class="pagination pagination-rounded mb-0">
              <!-- pagination -->
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
              ></b-pagination>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.ri-mail-send-line:hover {
  color: #1cbb8c;
}
</style>
<script>
import Vue from "vue";
import appConfig from "@/app.config";
import filterMixins from "../../../../mixins/filterData";
import moment from "moment";
import VueToast from "vue-toast-notification";
import Switches from "vue-switches";
import "vue-toast-notification/dist/theme-sugar.css";
Vue.use(VueToast);
export default {
  props: ["filters","telecallers"],
  mixins: [filterMixins],
  components: { 
    Switches
  },
  data() {
    return {
      users: [],
      primeUser: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 20, 50],
      filter: null,
      filterOn: [],
      sortBy: "total_views",
      sortDesc: true,
      doctorName: "",
      mobileNumber: null,
      fields: [
        { key: "doctor", sortable: true, label: "Doctor" },
        { key: "mobile_number", sortable: true, label: "Mobile No." },
        { key: "email", sortable: true, label: "Email" },
        { key: "speciality", sortable: true, label: "Speciality" },
        { key: "device_type", sortable: true, label: "Device Type" },
        { key: "member_type", sortable: true, label: "Member Type" },
        { key: "city", sortable: true, label: "City" },
        { key: "state", sortable: true, label: "State" },
        { key: "country", sortable: true, label: "Country" },
        { key: "is_prime", sortable: true, label: "Prime" },
        { key: "mci_verification_status", sortable: true, label: "MCI Status"},
        { key: "registered_at", sortable: true, label: "Registered At" },
        { key: "last_updated_at", sortable: true, label: "Last Updated At" },
        { key: "last_active_date", sortable: true, label: "Last Active Date" },
        { key: "agent_name", sortable: true, label: "Referred by"},
      ],
    };
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.getUsersList();
      },
    },
    telecallers : {
      deep : true,
      handler() {
        this.getUsersList();
      }
    },
    primeUser() {
      this.getUsersList();
    },
    // search() {
    //   this.getUsersList();
    // },
  },
  computed: {
    rows() {
      return this.users.length;
    },
  },
  methods: {
    searchByDoctor(event) {
      if (this.doctorName.length >= 3) {
        if (event.keyCode == 13) {
          this.getUsersList();
        }
      }
      else if (this.doctorName.length == 0 ) this.getUsersList();
    },
    searchByMobile(event) {
      if (this.mobileNumber.length >= 3) {
        if (event.keyCode == 13) {
          this.getUsersList();
        }
      }
      else if (this.mobileNumber.length == 0 ) this.getUsersList();
    },
    resetFilters() {
      if(!this.doctorName) {
        this.getUsersList();
      } 
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getUsersList() {
      const searchfiltersParams = this.getCleanFilteredData(this.filters);
      if(searchfiltersParams.telecaller_ids != undefined){
        searchfiltersParams.telecaller_ref_codes = this.telecallers.filter(f => searchfiltersParams.telecaller_ids.includes(f.id)).map(m => m.referral_code);
      }
      try {
        let user = this.$store.state.authfack.user;
        if(['AnalyticsAreaManager','AnalyticsRegionalManager'].includes(user.role)){
          if(searchfiltersParams.telecaller_ids == undefined || searchfiltersParams.telecaller_ids.length == 0){
            searchfiltersParams.telecaller_ref_codes = this.telecallers.map(t => t.referral_code);
          }
        }
        if (this.primeUser == true) {
          const response = await this.$http.get(
            appConfig.api_base_url + "/users?is_prime=true",
            {
              params: searchfiltersParams,
            },
          );
          this.users = response.data.users;
          this.users.forEach((u,ui) => {
            let t = this.telecallers.filter(f => (f.referral_code).toUpperCase() == (u.referred_by).toUpperCase());
            if(t.length > 0){
              this.users[ui].agent_name = t[0].username;
              this.users[ui].agent_type = t[0].is_field_mr != 1 ? 'Digi MR' : 'Field MR';
            }
          })
          this.totalRows = this.users.length;
        } else {
          // let params = Object.keys(this.filters).length > 0 ? this.filters : {};
          const response = await this.$http.get(
            appConfig.api_base_url + "/users",
            {
              params: searchfiltersParams,
            }
          );
          this.users = response.data.users;
          this.users.forEach((u,ui) => {
            let t = this.telecallers.filter(f => (f.referral_code).toUpperCase() == (u.referred_by).toUpperCase());
            if(t.length > 0){
              this.users[ui].agent_name = t[0].username;
              this.users[ui].agent_type = t[0].is_field_mr != 1 ? 'Digi MR' : 'Field MR';
            }
          })
          this.totalRows = this.users.length;
        }
      } catch (err) {
        console.log(err);
      }
    },
    getFormatedDate(date) {
      try {
        if (date != null) {
          return moment(date).format("yyyy-MM-DD");
        }
        return null;
      } catch (err) {
        console.log(err);
        return null;
      }
    },
  },
  mounted() {
    let user = this.$store.state.authfack.user;
    if(!['AnalyticsAreaManager','AnalyticsRegionalManager'].includes(user.role)) {
      this.getUsersList();
    }
  },
};
</script>
